//普通弹出框
function KYAlert(msg, title, callback, paras) {
    showKYDialog('alert', msg, title, callback, paras);
}

//普通弹出框（2秒后自动消失）
function KYMsg(msg, title, callback, paras) {
    showKYDialog('msg', msg, title, callback, paras);
}

//提示信息框
function KYInfo(msg, title, callback, paras) {
    showKYDialog('info', msg, title, callback, paras);
}

//警告框
function KYWarn(msg, title, callback, paras) {    
    showKYDialog('warn', msg, title, callback, paras);
}

//报错框
function KYError(msg, title, callback, paras) {
    showKYDialog('error', msg, title, callback, paras);
}

//确认框
function KYConfirm(msg, title, callback, paras, onCancelCallBack) {
    showKYDialog('confirm', msg, title, callback, paras, onCancelCallBack);
}

//确认框
function KYConfirmPage(msg, title, callback, paras, onCancelCallBack) {
    showKYDialog('confirmPage', msg, title, callback, paras, onCancelCallBack);
}
/*
    Kayang Pop Dialog, BY Todd 2019.12.28
    category     类别
    title        标题
    msg          内容
    callback     回调函数
*/
function showKYDialog(category, msg, title, callback, paras, onCancelCallBack) {
    var dialog = new PopUpDialog(category, msg, title, callback, paras, onCancelCallBack);

    if (category == 'confirm' || category == 'error' || category == 'confirmPage') {
        dialog.prompt();
    }
    else {
        dialog.alert();
    }
}

var PopUpDialog = function (category, msg, title, callback, paras, onCancelCallBack) {
    this.Category = category;
    this.Msg = $.trim(msg);
    var iTitle = 'Kayang e-HR ' + kygMsg(320782, '系统 ');
    if ($.trim(title)) iTitle = title;
    this.Title = iTitle;
    this.CallBack = callback;
    this.onCancelCallBack = onCancelCallBack;
    this.Paras = (paras instanceof Array) ? paras : $.trim(paras);
};

PopUpDialog.prototype = {
    alert: function () {
        if (/msg|warn/.test(this.Category)) {
            var topWin = $x.top();
            var callback = this.CallBack;
            var paras = this.Paras;
            var topMsgHTML = ' <div class="i-top-msg f-middle mtp-' + this.Category + '" onclick="$(this).remove()"><div class="i-msg-detail">' + this.Msg + '</div></div>';
            $(topWin.document.body).append(topMsgHTML);
            setTimeout(function () {
                topWin.$(".i-top-msg").addClass('i-top-msg-v')
                if (callback) callback(paras);
            }, 10);

            topWin.delayCloseTip(this.Category == 'warn');
        } else {
            var icon = 'tip_info.png';
            var imgSrc = $x.appRoot() + 'Styles/Images/Portal/' + icon;
            var dialogHTML = this.init();
            dialogHTML += '<div class="flex f-middle" style=" min-height:40px;">';
            dialogHTML += '<div class="tip-icon"><img src="' + imgSrc + '" width="24" height="24" alt=""></div>';
            dialogHTML += '<div class="tip-content" style=" line-height:20px;">';
            dialogHTML += '<span>' + this.Msg + '</span>';
            dialogHTML += '</div>';
            dialogHTML += '</div>';
            dialogHTML += '</div>';
            dialogHTML += ' <div class="tip-foot">';
            dialogHTML += ' <button id="pop-dialog-ok" type="button" class="button button-got">' + kygMsg(120026, '确认') + '</button>';
            dialogHTML += '</div>';
            dialogHTML += '</div>';
            this.render(dialogHTML);
        }
    },
    prompt: function () {
        var dialogHTML = this.init();
        if (this.Category == 'confirmPage') {
            var dialogWidth = 700;
            var dialogHeight = 680;
            var dialogHTML = '<div id="pop-tip-dialog" class="tooltip" style="display: none;width:' + dialogWidth + 'px;height:' + dialogHeight + 'px;z-index:2147483601;">';
            dialogHTML += '<iframe src="' + this.Msg + '" style="width:100%;height:100%;border: none;"></iframe>';
            dialogHTML += '</div>';
        } else {
            var icon = 'tip_judgement.png';
            if (this.Category == 'error') {
                icon = 'tip_error.png';
            }
            var imgSrc = $x.appRoot() + 'Styles/Images/Portal/' + icon;
            dialogHTML += '<div class="flex f-middle" style=" min-height:40px;">';
            dialogHTML += '<div class="tip-icon"><img src="' + imgSrc + '" width="24" height="24" alt=""></div>';
            dialogHTML += '<div class="tip-content">';
            dialogHTML += '<span>' + this.Msg + '</span>';
            dialogHTML += '</div>';
            dialogHTML += '</div>';
            dialogHTML += '</div> ';
            dialogHTML += '<div class="tip-foot">';
            dialogHTML += '<button id="pop-dialog-ok" type="button" class="button button-got">' + kygMsg(120026, '确认') + '</button>';
            if (this.Category == 'confirm') dialogHTML += '<button id="pop-dialog-cancel" type="button" class="button">' + kygMsg(120027, '取消') + '</button>';            
            dialogHTML += ' </div>';
            dialogHTML += '</div>';
        }
        this.render(dialogHTML);
    },
    init: function () {
        if (!/msg|warn/.test(this.Category)) this.addMask();
        $x.top().$("#pop-tip-dialog").remove();
        var dialogWidth = 400;
        var header = ' <div id="pop-tip-dialog" class="tooltip" style="display: none;width:' + dialogWidth + 'px;z-index:2147483601;">';
        header += '<div class="tip-title" style="width:' + dialogWidth + 'px;">';
        header += ' <div class="tip-title-name"><span>' + this.Title + '</span></div>';
        header += ' <div class="tip-close"><input type="button"></div>';
        header += '</div>';
        header += '<div  style="overflow: hidden; width:100%;" >';
        header += '   <div class="tip-main ">';
        return header;
    },
    render: function (dialogHTML) {
        var topWin = $x.top();
        topWin.$(topWin.document.body).append(dialogHTML);
        locateFixedCenter("pop-tip-dialog");
        topWin.$("#dialog-layer,#pop-tip-dialog").show();
        this.bindEvent();
    },
    bindEvent: function () {
        var topWin = $x.top();
        var paras = this.Paras;
        bindButton("#pop-dialog-ok", this.CallBack);
        bindButton("#pop-dialog-cancel", this.onCancelCallBack);
        topWin.$('.tip-foot button').on('keydown', checkTipEsc).eq(0).focus();
        var it = this;
        topWin.$('.tip-close').click(function () {
            if (it.Category != 'confirm') {
                it.CallBack && it.CallBack(paras)
            }
            it.onCancelCallBack && it.onCancelCallBack();
            closeTipDialog();
        });
        function bindButton(el, cb) {
            topWin.$(el).click(function () {
                topWin.setTimeout(function () {
                    if (typeof (cb) == 'function') {
                        cb && cb(paras);
                    } else {
                        eval(cb);
                    }
                }, 200);
                closeTipDialog();
            });
        }
        function checkTipEsc(e) {
            if (e.keyCode == 27) { //Esc
                it.onCancelCallBack && it.onCancelCallBack();
                closeTipDialog();
            }
        }
    },
    addMask: function () {
        var topWin = $x.top();
        topWin.$("#dialog-layer").remove();
        var doc = $(topWin.document);
        var mask = '<div id="dialog-layer" style="display:none;z-index:2147483600;width' + doc.outerWidth() + 'px;height:' + doc.outerHeight() + 'px" class="window-mask"></div>';
        $(topWin.document.body).append(mask);
    }
}

function locateFixedCenter(id) {
    var topWin = $x.top();
    var target = topWin.$("#" + id);
    if (!target) return false;
    $(target).css({
        position: 'fixed',
        left: ($(topWin).width() - $(target).outerWidth()) / 2,
        top: ($(topWin).height() - $(target).outerHeight()) / 2
    });
}
function delayCloseTip(isWarn) {
    setTimeout(function () {
        $x.top().$(".i-top-msg").remove();
    }, isWarn ? 5000 : 2500);
}
function closeTipDialog() {
    var topWin = $x.top();
    topWin.$("#dialog-layer").remove();
    topWin.$("#pop-tip-dialog").remove();
    topWin.$(".i-top-msg").remove();
}

function viewLog() {

    var topWind = $x.top();
    topWind.$("#pop-tip-dialog,.window-mask").remove();

    var w = getTopWindWidth();
    var h = getTopWindHeight() - 30;

    var targetUrl = $x.appRoot() + "Home/winViewlog.aspx";
    if (topWind.openIFrDlg) {
        topWind.openIFrDlg(targetUrl, w, h, { fnClose: function () { } });
    } else {
        topWind.open(targetUrl);
    }
}
